import { createRouter, createWebHistory } from 'vue-router'
import packageJson from '../../package.json'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/home.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    // 아래처럼 바로 NotFound 경로를 매칭해도 됨
  },
  {
    path: '/404',
    component: () => import('../components/404_error.vue'),
  },
  {
    path: '/lev_ai',
    name: 'lev_ai',
    component: () => import('../components/ai_platform/lev_ai.vue'),
  },
  {
    path: '/lev_cops',
    name: 'lev_cops',
    component: () => import('../components/smart_cost/lev_cops.vue'),
  },
  {
    path: '/lev_pdoc',
    name: 'lev_pdoc',
    component: () => import('../components/smart_cost/lev_pdoc.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../components/news.vue'),
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../components/about/company.vue'),
  },
  {
    path: '/news_list',
    name: 'news_list',
    component: () => import('../components/news/news_list.vue'),
    meta: { authorization: ['token'] },
  },
  {
    path: '/news_detail',
    name: 'news_detail',
    component: () => import('../components/news/news_detail.vue'),
  },
  {
    path: '/news_write',
    name: 'news_write',
    component: () => import('../components/news/news_write.vue'),
    meta: { authorization: ['token'] },
  },
  {
    path: '/news_edit',
    name: 'news_edit',
    component: () => import('../components/news/news_edit.vue'),
    meta: { authorization: ['token'] },
  },
  {
    path: '/admin_login',
    name: 'admin_login',
    component: () => import('../components/admin_login.vue'),
  },
  {
    path: '/medi_xm',
    name: 'medi_xm',
    component: () => import('../components/model/medi_xm.vue'),
  },
  {
    path: '/edue_xm',
    name: 'edue_xm',
    component: () => import('../components/model/edue_xm.vue'),
  },
  {
    path: '/soci_xm',
    name: 'soci_xm',
    component: () => import('../components/model/soci_xm.vue'),
  },
  {
    path: '/fact_xm',
    name: 'fact_xm',
    component: () => import('../components/model/fact_xm.vue'),
  },
  {
    path: '/farm_xm',
    name: 'farm_xm',
    component: () => import('../components/model/farm_xm.vue'),
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.afterEach((to) => {
  const title = to.meta.title || packageJson.name
  if (title) document.title = title
})


router.beforeEach((to, from, next) => {
  const requiredAuthorization = to.matched.some(
    (record) => record.meta.authorization
  )
  const token = sessionStorage.getItem('jwt_token')
  if (requiredAuthorization && !token) {
    next({ name: 'admin_login' })
  } else {
    // 그 외의 경우에는 그냥 next 함수를 호출하여 다음 페이지로 이동합니다.
    next()
  }

})


export default router
