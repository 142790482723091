import { createStore } from 'vuex'

const store = createStore({
  state: {
    isLoggedIn: false,
    token: sessionStorage.getItem('jwt_token') || '',
  },
  mutations: {
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload
    },
    setToken(state, payload) {
      state.token = payload
      sessionStorage.setItem('jwt_token', payload)
    },
  },
  actions: {
    setIsLoggedIn({ commit }, payload) {
      commit('setIsLoggedIn', payload)
    },
    setToken({ commit }, payload) {
      commit('setToken', payload)
      // 일정 시간동안 유지하기 위한 코드
      setTimeout(() => {
        commit('setToken', '')
        commit('setIsLoggedIn', false)
      }, 1800000) // 30분
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    token: (state) => state.token,
  },
})

export default store
